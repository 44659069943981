import Pages from "./Pages";
import Init from "./Init";

function App() {
  return (
    <>
      <Init />
      <Pages />
    </>
  );
}

export default App;
