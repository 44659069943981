import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageConfig from "./configs/page.config.json";

const Announcement = lazy(() => import("./pages/announcement"));
const Carpark = lazy(() => import("./pages/carpark"));
const PaymentBill = lazy(() => import("./pages/payment/bill"));
const PaymentOnline = lazy(() => import("./pages/payment/online"));
const PaymentResult = lazy(() => import("./pages/payment/result"));
const FeeQuery = lazy(() => import("./pages/fee/query"));
const FeeResult = lazy(() => import("./pages/fee/result"));
const ReceiptQuery = lazy(() => import("./pages/receipt/query"));
const ReceiptResult = lazy(() => import("./pages/receipt/result"));
const Repair = lazy(() => import("./Repair"));

const Layout = lazy(() => import("./components/layout"));
const Loading = lazy(() => import("./components/loading"));

// import Announcement from "./pages/announcement";
// import Carpark from "./pages/carpark";
// import PaymentBill from "./pages/payment/bill";
// import PaymentOnline from "./pages/payment/online";
// import PaymentResult from "./pages/payment/result";
// import FeeQuery from "./pages/fee/query";
// import FeeResult from "./pages/fee/result";
// import ReceiptQuery from "./pages/receipt/query";
// import ReceiptResult from "./pages/receipt/result";

// import Layout from "./components/layout";
// import Loading from "./components/loading";

/**
 * This component is used to render the pages.
 * @returns {JSX.Element} - The component JSX element.
 */
const Pages = () => {
  const routesObj =
    process.env.REACT_APP_FIX_MODE === "true"
      ? {
          "/": <Repair />,
          "/news": <Announcement />,
          "/carpark": <Carpark />,
          "/payment/bill": <Repair />,
          "/payment/online": <Repair />,
          "/payment/result": <Repair />,
          "/fee/result": <Repair />,
          "/receipt/query": <Repair />,
          "/receipt/result": <Repair />,
        }
      : {
          "/": <FeeQuery />,
          "/news": <Announcement />,
          "/carpark": <Carpark />,
          "/payment/bill": <PaymentBill />,
          "/payment/online": <PaymentOnline />,
          "/payment/result": <PaymentResult />,
          "/fee/result": <FeeResult />,
          "/receipt/query": <ReceiptQuery />,
          "/receipt/result": <ReceiptResult />,
        };
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loading directlyShow />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              {Object.keys(PageConfig).map((route) => {
                return (
                  <Route key={route} path={route} element={routesObj[route]} />
                );
              })}
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
      <Loading />
    </>
  );
};

export default Pages;
