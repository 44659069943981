import { useState } from "react";
/**
 * @typedef {Object} LoadingContext
 * @property {boolean} loadingFlag - The loading flag.
 * @property {function(boolean): void} setLoadingFlag - The function to set the loading flag.
 * @property {function(() => void): Promise<void>} loadingCb - The function to set the loading flag to true, execute the callback, and set the loading flag to false.
 */

/**
 * This hook is used to handle the loading flag.
 * @returns {LoadingContext} - The loading flag and its setter.
 */
const useLoading = () => {
  const [loadingFlag, setLoadingFlag] = useState(false);

  /**
   * This function is used to set the loading flag to true, execute the callback, and set the loading flag to false.
   * @param {async () => void} callback - The callback function.
   */
  const loadingCb = async (callback) => {
    setLoadingFlag(true);
    await callback();
    setLoadingFlag(false);
  };

  return { loadingFlag, setLoadingFlag, loadingCb };
};

export default useLoading;
