import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const version = "-240513-0";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json`,
        },
        lng: "zh-TW",
        fallbackLng: "zh-TW",
        debug: false,
        load: "currentOnly",
        ns: [`translation${version}`],
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
