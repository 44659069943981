import React, { createContext, useContext } from "react";
import useLoading, { LoadingContext } from "../hooks/useLoading";

const loadingContext = createContext();

/**
 * Custom hook to access the loading context.
 * @returns {LoadingContext} The loading context.
 * @throws {Error} Throws an error if used outside the context provider.
 * @example
 * const { loadingFlag, setLoadingFlag, loadingCb } = useLoadingContext();
 */
export const useLoadingContext = () => useContext(loadingContext);

/**
 * Provider component for the loading context.
 * @param {object} props - React component props.
 * @param {React.ReactNode} props.children - The child components wrapped by the LoadingProvider.
 * @returns {React.ReactNode} - JSX for the LoadingProvider.
 */
export const LoadingProvider = ({ children }) => {
  // Initialize the loading context value using the useLoading hook.
  const loadingContextValue = useLoading();

  return (
    <loadingContext.Provider value={loadingContextValue}>
      {children}
    </loadingContext.Provider>
  );
};
